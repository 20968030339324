























































import { Vue, Component, Prop } from "vue-property-decorator";
import { Input, Select, Option } from "element-ui";
import { CropVariable, CropVariety, Crop, Checkbox } from "@/store/models/cropVariety";

@Component({
  components: { Input, Select, Option }
})
export default class DynamicFieldNepali extends Vue {
  all = [] as any;
  selected: boolean = true;

  @Prop({ default: () => [] })
  public meta!: { [key: string]: CropVariable[] };

  created() {}
}
