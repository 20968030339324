import { render, staticRenderFns } from "./DemandList.vue?vue&type=template&id=3cf5bba7&scoped=true&"
import script from "./DemandList.vue?vue&type=script&lang=ts&"
export * from "./DemandList.vue?vue&type=script&lang=ts&"
import style0 from "./DemandList.vue?vue&type=style&index=0&id=3cf5bba7&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf5bba7",
  null
  
)

export default component.exports